<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import type { YoutubeBlockProps } from '$lib/storyblok/types';
	import StoryblokVideo from '$lib/components/Storyblok/StoryblokVideo.svelte';

	export let blok: YoutubeBlockProps;
</script>

<div use:storyblokEditable={blok}>
	<StoryblokVideo
		video_id={blok.video_id}
		autoplay={blok.autoplay}
		class="min-h-max max-h-204 2xl:max-h-188 aspect-18/9"
	/>
</div>
