<script>
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import DisclosureContent from '$lib/components/FAQ/DisclosureContent.svelte';

	export let section;
	export let blok = undefined;

	$: data = section || blok;
</script>

<div class={blok ? 'flex flex-row justify-center ' : ''}>
	<div class="h-6 relative w-full mb-6">
		<div
			style:background={data.section === 'sprenger'
				? 'var(--accent-primary)'
				: `var(--special-${data.section})`}
			class={blok ? 'absolute right-0 h-6 w-full' : 'absolute right-0 h-6 w-screen'}
		/>
	</div>
	<h2
		class="mb-10 uppercase font-extrabold text-h-32"
		id={`${data.title}`}
		style:color={data.section === 'sprenger'
			? 'var(--accent-primary)'
			: `var(--special-${data.section})`}
	>
		{data.title}
	</h2>
	<div class="space-y-20 mb-20">
		{#each data.entries || [] as entry}
			<div class="my-8">
				{#if !(data.entries.length === 1 && entry.title === data.title)}
					<h3 id={data.title + entry.title} class="text-h-24 scroll-mt-36 lg:scroll-mt-24 mb-4">
						{entry.title}
					</h3>
				{/if}
				{#each entry.entries || [] as e}
					<DisclosureContent title={e.title} text={e.text} youtube={e.youtube} />
				{/each}
			</div>
		{/each}
		<!--  {section.entries.map((entry) => (
            <div key={entry.title} id={section.title + entry.title} class="mb-8 mt-8">
                {!(section.entries.length === 1 && entry.title === section.title) && (
                    <h3 class="text-h-24 scroll-mt-36 lg:scroll-mt-24 mb-4">{entry.title}</h3>
                )}
                {entry.entries?.map((e) => (
                    <DisclosureContent seo_answer={e.seo_answer} title={e.title} key={e.title}>
                        <RichText class="mb-8" text={e.text} />
                        {e.youtube &&
                            e.youtube.map((youtube, i) => <YoutubeBlock blok={youtube} key={i} />)}
                    </DisclosureContent>
                ))}
            </div>
        ))} -->
	</div>
</div>
