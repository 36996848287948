<script>
	import GenericDisclosure from '$lib/components/GenericDisclosure.svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import YouTubeBlock from '$lib/components/Storyblok/YouTubeBlock.svelte';

	export let title;
	export let text;
	export let youtube;
	let open = false;
</script>

<GenericDisclosure bind:open>
	<div
		on:click={() => {
			open = !open;
		}}
		on:keydown={(e) => {
			if (e.key === 'Enter') {
				open = !open;
			}
		}}
		role="button"
		tabindex="0"
		class={classes(
			'w-full min-h-[5rem] hover:cursor-pointer hover:text-dark-secondary border-dark-quaternary text-h-16 leading-6 flex justify-between items-center',
			open ? '' : 'border-b'
		)}
	>
		<h4 class="font-bold text-h-16 flex-1 text-left" itemProp="name">
			{title}
		</h4>
		<div
			class="w-6 h-6 flex-0 i-sprenger-chevron-down transform transition duration-300"
			class:rotate-180={open}
		></div>
	</div>
	<div slot="content-open">
		<RichText class="mb-8" {text} />
		{#each youtube || [] as video}
			<YouTubeBlock blok={video} />
		{/each}
	</div>
</GenericDisclosure>
